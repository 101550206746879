.navbar ul {
    @apply flex flex-col lg:flex-row lg:items-center gap-10 md:gap-12 lg:gap-8 px-6 md:px-8 lg:px-0 pt-6 md:pt-10 lg:pt-0 w-3/5 lg:w-auto bg-primary dark:bg-dark-primary h-screen lg:h-auto;
}
.navbar ul li {
    @apply font-bold text-lg uppercase;
}
.navbar-container {
    @apply flex items-center justify-between bg-primary dark:bg-dark-primary mb-10 px-5 md:px-10 py-7 lg:py-10 lg:px-20;
}

.menu-icon {
    @apply text-2xl;
}

.navbar--show {
    @apply fixed z-10 inset-0 w-screen h-screen bg-dark-color/45;
}
.logo {
    @apply h-8 md:h-14 lg:h-20;
    width: auto;
}

.link-active {
    @apply text-white-color relative;
}

.link-active::after {
    content: ' ';
    @apply w-8 h-1 rounded-full bg-text-primary hidden lg:absolute top-8 right-0 left-0 m-auto;
}

.darkMode-icon-wrapper {
    @apply p-2 cursor-pointer rounded-full border-[1px] border-solid border-white-color;
}

.sun-icon {
    @apply text-base;
}
