.link-wrapper {
    @apply md:flex flex-col gap-5 md:gap-7 lg:gap-12 md:w-[200px] lg:w-[237px] hidden;
}

.link-wrapper-unordered {
    @apply flex flex-wrap md:flex-nowrap md:flex-col gap-4 md:gap-6;
}

.link-text {
    @apply font-semibold text-dark-color hover:text-secondary dark:text-secondary dark:hover:text-dark-color;
}

.footer-items-container {
    @apply flex flex-col gap-7 md:gap-10 lg:gap-0 lg:flex-row justify-between pb-16 border-b-2 border-b-secondary;
}

.link-list {
    @apply flex flex-col md:flex-row justify-between gap-10 md:gap-0 lg:gap-10;
}