/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
    margin-block-end: 0;
}

html, body {
    @apply dark:bg-dark-color bg-white-color dark:text-secondary;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
    line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
    text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
    color: currentColor;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
    font-family: inherit;
    font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
    min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
    scroll-margin-block: 5ex;
}

.flex-layout--center {
    @apply flex items-center justify-center;
}

.flex-layout--start {
    @apply flex items-start justify-center;
}

.flex-layout--end {
    @apply flex items-end justify-center;
}

.general-page-layout {
    @apply mx-5 md:mx-14 lg:mx-[120px];
}

.body-text--normal {
    @apply font-dm-sans text-[1.125rem] leading-normal;
}

.shadow-drop {
    box-shadow: 0 6px 32px 0 rgba(0, 0, 0, 0.15);
}

.shadow-fade {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
}

.text-h2 {
    @apply font-bold text-2xl lg:text-[2rem] tracking-normal font-grotesque text-center;
}

.smooth-transition {
    @apply transition-all duration-300 ease-in-out;
}

.h2 {
    @apply font-bold text-[2rem] leading-normal font-grotesque;
}

.h3 {
    @apply text-2xl font-bold font-grotesque text-dark-color dark:text-secondary;
}

.h0 {
    @apply font-grotesque font-bold text-5xl md:text-6xl lg:text-[5rem] leading-normal -tracking-[0.8px];
}

.layout-text {
    @apply text-center md:text-left;
}
